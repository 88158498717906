:root{
  --bs-primary: #5B2D2C
}
body{ background-color: #FAE7D7; font-family: 'Public Sans', sans-serif; font-size: 14px;}
.btn, .card, .alert, .form-control, .form-select{ border-radius: 3px;}
.card{ border: none; border-radius: 3px;}
.login-wrapper{ max-width: 400px; margin: 40px auto; padding: 15px;}
.btn{ font-size: 14px;}
.form-control:focus{ box-shadow: none;}
.btn-xs{ font-size: 12px; padding: 2px 10px;}
.login-logo{ text-align: center; display: flex; justify-content: center;}
a{ text-decoration: none;}

.header{ background-color: #fff; }
.menu-outer{ background-color: #5B2D2C;}
.header-menu{display: flex; justify-content: space-between; align-items: center;}
.header-menu ul{ list-style: none; margin: 0; padding: 0;}
.header-menu ul li{ display: inline; position: relative;}
.header-menu ul li a{ display: inline-block; padding: 10px 25px; font-size: 12px; color: #fff; text-decoration: none; border-right: solid 1px #804544;}
.header-menu ul li:last-child > a{ border: none;}
.header-menu ul ul{ position: absolute; left: 0; top: 27px; background-color: #5B2D2C; width: 160px; display: none; z-index: 99;}
.header-menu ul ul a{ display: block; border-right: none; border-bottom: solid 1px #804544; padding-left: 10px; padding-right: 10px;}
.header-menu ul li:hover ul{ display: block;}
.header-menu ul li:hover > a{ background-color: #31100f;}

.dashboard-container{ padding: 15px;}

.page-header{ display: flex; justify-content: space-between; align-items: center; margin-bottom: 15px;}
.cardbox{ background-color: #fff; border-radius: 3px;}

.card-button{ text-decoration: none; color: #31100f; border: solid 1px #EEE; border-radius: 3px;}
.card-button:hover{ color: #a52a2a;}
.badge{ border-radius: 2px;}
.widget{ border-radius: 3px; margin-bottom: 10px;}
.widget-body{ padding: 10px;}

.color-primary{ background-color: #5B2D2C;;}
.color-pink{ background-color: #F2B5B6;}
.color-parrot{ background-color: #D1EFD6;}
.color-pink1{ background-color: #F5C9D5;}
.color-orange{ background-color: #FDB777;}
.color-info{ background-color: #D2E1EC;}
.color-dark{ background-color: #121212;}
.color-purple{ background-color: #C6B5F2;}
.color-orange1{ background-color: #FEC0A7;}
.color-orange2{ background-color: #FC8922;}

.offcanvas{ background-color: #111;}
.offcanvas ul{ list-style: none; margin: 0; padding: 0;}
.offcanvas ul li{ border-bottom: solid 1px #444;}
.offcanvas ul li:last-child{ border: none;}
.offcanvas ul li a{color: #fff; display: block; padding: 15px 0;}
.offcanvas ul ul{ padding-left: 10px; display: none;}

.chip-button{ font-size: 12px; color: #111; text-decoration: none;}
.chip-button-active{ font-size: 12px; color: #111; text-decoration: underline;}

.home-transactions ul{ list-style: none; margin: 0; padding: 0;}
.home-transactions ul li{ display: flex; align-items: center; margin-bottom: 15px;}
.home-transactions ul li .content-body{ flex: 1; padding: 0 10px;}
.home-transactions h6{ font-size: 14px; margin-bottom: 0;}
.home-transactions ul li .amount{ font-size: 14px;}
.home-transactions ul li .inr{ font-size: 12px; color: #888;}

.user-items ul{ list-style: none; margin: 0; padding: 0; font-size: 12px; display: flex; justify-content: space-between;}
.user-items ul li{ font-size: 10px; margin-right: 20px; flex: 1;}
.user-items ul li:last-child{ margin-right: 0;}
.more-buttons{white-space: nowrap;}
.more-buttons::-webkit-scrollbar{ display: none;}
.search-result .search-item{ border-bottom: solid 1px #EEE;}
.search-result .search-item:last-child{ border: none;}

.account-menu{ background-color: #5B2D2C; margin-bottom: 15px;}
.account-menu ul{ list-style: none; padding: 0; margin: 0; display: flex; justify-content: space-between;}
.account-menu ul li:last-child{ border: none;}
.account-menu ul li a{ display: inline-block; padding: 10px 20px; color: #fff;}

.item-row{ display: flex; border-bottom: solid 1px #EEE; padding: 10px;}
.item-row .label-item-row{ width: 200px;}
.item-row .label-item-value{ flex: 1}

.form-control{ border-radius: 3px;}

@media (max-width: 768px) {
  .header-menu{ display: none;}
  .header-menu ul{ display: block;}
}
